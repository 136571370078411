
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { indexedDBLocalPersistence, getAuth, setPersistence } from "firebase/auth";
import { disableNetwork, enableMultiTabIndexedDbPersistence, enableNetwork, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
import { getToken, deleteToken, getMessaging } from "firebase/messaging";
import { getPerformance } from "firebase/performance";
import { connectFunctionsEmulator } from "firebase/functions";
import { connectAuthEmulator } from "firebase/auth";
import { connectFirestoreEmulator } from "firebase/firestore";
import { connectStorageEmulator } from "firebase/storage";

import { ConfigMap } from "./configMap";
const firebaseConfig = ConfigMap[process.env.VUE_APP_ENV];

const fcmConfig = {
  vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY
};

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
export const analytics = getAnalytics(app)
export const functions = getFunctions(app, "australia-southeast1")
export const messaging = getMessaging(app);
export const performance = getPerformance(app);
export const getFCMToken = () => getToken(messaging, fcmConfig);
export const deleteFCMToken = () => deleteToken(messaging);

// enable local emulators
if (process.env.VUE_APP_EMULATED) {
  console.debug("connecting emulators…");
  connectAuthEmulator(auth, "http://localhost:9099")
  connectFirestoreEmulator(db, "localhost", 8080)
  connectFunctionsEmulator(functions, "localhost", 5001)
  connectStorageEmulator(storage, "localhost", 9199)
}
if (process.env.VUE_APP_FUNCTIONS_EMULATED) {
  console.debug("connecting functions emulator on port 5001…");
  connectFunctionsEmulator(functions, "localhost", 5001);
}

// enable persistence on all routes except those with 'disablePersistence' meta
// usually we want it, but e.g. the LGA embed page needs to work in an iframe
// and persistence causes issues here.
let persistenceEnabled = false;
let persistenceCleared = false;

export async function enablePersistence() {
  if (persistenceEnabled) return;

  console.debug("enabling persistence…");

  try {
    await setPersistence(auth, indexedDBLocalPersistence);
    console.debug("auth persistence set");
  } catch (e) {
    console.error(`auth persistence failed: ${e.code}: ${e.message}`);
  }

  try {
    await enableMultiTabIndexedDbPersistence(db);
    console.debug("firestore persistence set");
  } catch (e) {
    console.error(`firestore persistence failed: ${e.code}: ${e.message}`);
  }

  persistenceEnabled = true;
  console.debug("persistence enabled");
}

export async function disablePersistence() {
  if (persistenceCleared) return;

  // clear the auth cache
  try {
    await setPersistence(auth, null);
    console.debug("auth persistence cleared");
  } catch (e) {
    console.error(`auth persistence failed: ${e.code}: ${e.message}`);
  }

  // clear the firestore cache
  try {
    await disableNetwork(db);
    await enableNetwork(db);
    console.debug("firestore persistence cleared");
  } catch (e) {
    console.error(`firestore persistence failed: ${e.code}: ${e.message}`);
  }

  persistenceCleared = true;
  console.debug("persistence disabled");
}