<template>
  <help-more-info v-bind="$attrs" learn-more-url="/help/closure-administration">
    <p>
      Staff are able to set an estimated reopening date and time on closures.
      This can be updated at any time.
    </p>

    <p>
      Each morning, a reminder notification will be generated that will notify
      all relevant staff of the closures that are estimated to reopend that day.
      Closures that are overdue for reopening are also provided.
    </p>

    <p>
      Some closures may be reopened automatically when the estimated reopening
      time passes. This is specified in the closure details along with the date
      and time of reopening.
    </p>
  </help-more-info>
</template>

<script>
export default {
  name: "help-closure-estimated-reopening",
};
</script>

<style lang="scss" scoped></style>
