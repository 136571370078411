import { doc, collection, addDoc, updateDoc, arrayUnion, arrayRemove, serverTimestamp } from "firebase/firestore";
import { db } from "@/firebase/config";
import moment from "moment";
import { latLngToArray, latLngToLiteral } from "@/util";
import { reverseGeocode } from "@/google/geocode";
import { geohashForLocation } from "geofire-common";

const makeRef = (id) => doc(db, "closures", id);

export default {
  namespaced: true,

  actions: {

    createNewClosureObject(context, options = {}) {
      const CLOSURE_DEFAULTS = {
        active: true,
        createdAt: moment().unix(),
        regions: [],
      };
      return { id: null, ...CLOSURE_DEFAULTS, ...options };
    },

    async nearestLocation(context, latLng) {
      const latLngLiteral = latLngToLiteral(latLng);
      const url = "https://router.project-osrm.org/nearest/v1/driving";
      const response = await fetch(`${url}/${latLngLiteral.lng},${latLngLiteral.lat}`);
      const json = await response.json();
      const { name, location, distance } = json.waypoints[0];
      return { name, location, distance };
    },

    async formattedAddressFromLatLng(context, latLng) {
      try {
        const response = await reverseGeocode(latLngToLiteral(latLng));
        console.debug(`[closureWrite] formattedAddressFromLatLng`, response);
        return response.results[0]?.formatted_address || null;
      } catch (e) {
        console.error(`reverse geocode failed: ${e.message}`);
        return null;
      }
    },

    async create({ dispatch }, closure) {
      console.debug("[closureWrite] create", { ...closure });
      closure.geohash = geohashForLocation(latLngToArray(closure.position).reverse());
      const docRef = await addDoc(collection(db, "closures"), {
        updatedAt: serverTimestamp(),
        ...closure,
      });
      closure.id = docRef.id;
      await dispatch("logClosureActivity", {
        closureId: docRef.id,
        action: "createClosure",
        payload: closure,
      });
      return docRef.id;
    },

    async createFromCluster({ dispatch }, closure) {
      console.debug("[closureWrite] createFromCluster", { ...closure });
      closure.geohash = geohashForLocation(latLngToArray(closure.position).reverse());
      const docRef = await addDoc(collection(db, "closures"), {
        updatedAt: serverTimestamp(),
        ...closure,
      });
      closure.id = docRef.id;
      await dispatch("logClosureActivity", {
        closureId: docRef.id,
        action: "createClosureFromCluster",
        payload: closure,
      });
      return docRef.id;
    },

    async saveDetails({ dispatch }, { closureId, details }) {
      console.debug("[closureWrite] saveDetails", details);
      if (details.position) details.geohash = await geohashForLocation(latLngToArray(details.position).reverse())
      await updateDoc(makeRef(closureId), { updatedAt: serverTimestamp(), ...details });
      await dispatch("logClosureActivity", {
        closureId: closureId,
        action: "saveDetails",
        payload: details,
      });
      return closureId;
    },

    async reopen({ dispatch }, closureId) {
      const patch = { condition: "opened", openedAt: moment().unix(), estimatedReopening: null, updatedAt: serverTimestamp() };
      try {
        await updateDoc(makeRef(closureId), patch);
      } catch (e) {
        throw new Error(`Failed to update closure document: ${e.message}`);
      }

      try {
        await dispatch("logClosureActivity", {
          closureId: closureId,
          action: "reopenClosure",
          payload: { openedAt: patch.openedAt },
        });
      } catch (e) {
        throw new Error(`Failed to log closure activity: ${e.message}`);
      }

      try {
        await dispatch("closureRead/onReopenClosure", { closureId, patch }, { root: true });
      } catch (e) {
        throw new Error(`Failed to update the vuex store with reopened closure: ${e.message}`);
      }
    },

    async saveMetadata({ dispatch }, { closureId, metadata }) {
      await updateDoc(makeRef(closureId), { metadata, });
      await dispatch("logClosureActivity", {
        closureId: closureId,
        action: "saveMetadata",
        payload: metadata,
      });
    },

    async savePolyline({ dispatch, rootGetters }, { closureId, polyline, ...payload }) {
      console.debug("[closureWrite] savePolyline", closureId, polyline, payload);

      const closure = rootGetters["closureRead/transformedClosures"].find(c => c.id === closureId);

      let regions = [];
      if (closure?.regions?.length) {
        regions = closure.regions.map((r, i) =>
          i === 0 ? { ...r, polyline, updatedAt: moment().unix() } : r
        );
      } else {
        regions = [{
          id: `region_${moment().unix()}`,
          polyline,
          createdAt: moment().unix(),
          updatedAt: moment().unix()
        }];
      }

      await updateDoc(makeRef(closureId), {
        updatedAt: serverTimestamp(),
        regions,
        polyline,
        ...payload
      });

      await dispatch("logClosureActivity", {
        closureId,
        action: "savePolyline",
        payload: { polyline, ...payload }
      });
    },

    async saveDetourPolyline({ dispatch }, { closureId, ...payload }) {
      console.debug("[closureWrite] saveDetourPolyline", closureId, payload);
      await updateDoc(makeRef(closureId), { updatedAt: serverTimestamp(), ...payload });
      await dispatch("logClosureActivity", {
        closureId: closureId,
        action: "saveDetourPolyline",
        payload
      });
    },

    async logClosureActivity({ rootGetters }, { closureId, user, action, payload }) {
      if (!user) user = rootGetters["auth/serialisedUser"];
      const timestamp = moment().unix();
      const activity = { timestamp, closureId, user, action, payload };
      const docRef = await addDoc(collection(db, "closures", closureId, "activities"), activity);
      activity.id = docRef.id;
      return activity;
    },

    async updateClosureMedia({ dispatch, rootGetters }, { id, media }) {
      await dispatch("app/setLoading", true, { root: true });
      await updateDoc(doc(db, "closures", id), { updatedAt: serverTimestamp(), media: arrayUnion(...media) });
      const activity = await dispatch("logClosureActivity", {
        closureId: id,
        user: rootGetters["auth/serialisedUser"],
        action: "updateClosureMedia",
        payload: media,
      });
      const mapMediaUpdatePromises = (mediaItem) => {
        const payload = {
          mediaId: mediaItem.id,
          collectionName: `closures/${id}/activities`,
          documentId: activity.id,
          field: "payload",
        };
        return dispatch("media/addReference", payload, { root: true });
      };
      await Promise.all(media.map(mapMediaUpdatePromises));
      await dispatch("app/setLoading", false, { root: true });
    },

    async removeClosureMediaItems(context, { closureId, mediaItems }) {
      await updateDoc(doc(db, "closures", closureId), { updatedAt: serverTimestamp(), media: arrayRemove(...mediaItems) })
      await Promise.all(mediaItems.map((item) => {
        return context.dispatch("media/removeReference", {
          mediaId: item.id,
          collectionName: "closures",
          documentId: closureId
        }, { root: true });
      }));
      await context.dispatch("logClosureActivity", {
        closureId,
        user: context.rootGetters["auth/serialisedUser"],
        action: "removeClosureMediaItems",
        payload: mediaItems,
      });
    },

    async deleteClosure(context, closure) {
      const docRef = doc(db, "closures", closure.id);
      await updateDoc(docRef, { updatedAt: serverTimestamp(), deletedAt: serverTimestamp(), active: false });
      await context.dispatch("logClosureActivity", {
        closureId: closure.id,
        user: context.rootGetters["auth/serialisedUser"],
        action: "deleteClosure",
        payload: {},
      });
    },

    async saveRegions({ dispatch }, { closureId, regions, position, nearestLocation, formattedAddress }) {
      console.debug("[closureWrite] saveRegions", closureId, { regions, position, nearestLocation, formattedAddress });
      await updateDoc(makeRef(closureId), {
        updatedAt: serverTimestamp(),
        regions,
        polyline: regions[0]?.polyline || null,
        position,
        nearestLocation,
        formattedAddress,
      });
      await dispatch("logClosureActivity", {
        closureId,
        action: "saveRegions",
        payload: { regions }
      });
    },


    async deleteRegion({ dispatch, rootGetters }, { closureId, regionId }) {
      console.debug("[closureWrite] deleteRegion", closureId, regionId);

      const closure = rootGetters["closureRead/transformedClosures"].find(c => c.id === closureId);
      if (!closure) throw new Error(`Closure ${closureId} not found`);

      const regions = closure.regions.filter(r => r.id !== regionId);

      await updateDoc(makeRef(closureId), {
        updatedAt: serverTimestamp(),
        regions,
        polyline: regions[0]?.polyline || null
      });

      await dispatch("logClosureActivity", {
        closureId,
        action: "deleteRegion",
        payload: { regionId }
      });
    },
  },

}